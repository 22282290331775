export default {
  state: {
    config: {
      社会主义核心价值观: {
        defaultLink: 'http://www.xinhuanet.com/',
        topMenu: [
          {
            title: '新华网',
            link: 'http://www.xinhuanet.com/'
          },
          {
            title: '央视网',
            link: 'http://news.cctv.com/mobile/'
          },
          {
            title: '人民网',
            link: 'http://www.people.com.cn/'
          }
        ]
      },
      新时代讲习所: {
        title: '新时代文明实践中心'
      },
      新篇章: {
        sections: [
          { title: '在习近平新时代中国特色社会主义思想指导下——新时代 新作为 新篇章', category: '新篇章' },
          { title: '决战决胜走基层 总攻之势大采访——千名记者一线行', category: '千名记者一线行' }
        ]
      },
      脱贫攻坚: {
        title: '决胜全面小康 决战脱贫攻坚'
      },
      建国70周年: {
        title: '建国70周年',
        topMenuDefaultCategory: '建国70周年-其它文章',
        topMenu: [
          {
            title: '唱响红歌',
            category: '唱响红歌',
            submenu: [
              { title: '选手风采', category: '建国70周年-选手风采' },
              {
                title: '比赛回顾',
                category: '比赛回顾',
                submenu: [
                  { title: '初赛', category: '建国70周年-初赛' },
                  { title: '复赛', category: '建国70周年-复赛' },
                  { title: '决赛', category: '建国70周年-决赛' },
                  { title: '大合唱', category: '建国70周年-大合唱' }
                ]
              },
              { title: '红歌大家学', category: '建国70周年-红歌大家学' }
            ]
          },
          { title: '其它文章', category: '建国70周年-其它文章' }
        ]
      },
      征集: {
        title: '征集',
        topMenuDefaultCategory: '征集-微视频',
        topMenu: [
          { title: '微视频', category: '征集-微视频' },
          { title: '专题活动', category: '征集-专题活动' },
          { title: '摄影', category: '征集-摄影' },
          { title: '诵读', category: '征集-诵读' },
          { title: '歌曲', category: '征集-歌曲' }
        ]
      },
      网络中国节中秋: {
        title: '网络中国节•中秋'
      },
      网络中国节重阳: {
        title: '网络中国节•重阳'
      },
      脱贫感恩党奋进新起点: {
        sections: [
          { title: '脱贫感恩党，奋进新起点', category: '脱贫感恩党奋进新起点' },
          { title: '打好六大会战，奋力冲刺收官', category: '打好六大会战，奋力冲刺收官' }
        ]
      },
      学习五中全会: {
        title: '深入学习宣传贯彻党的十九届五中全会精神'
      },
      '义务教育有保障': {
        title: '【脱贫感党恩 奋进新起点】义务教育有保障'
      },
      '基本医疗有保障': {
        title: '【脱贫感党恩 奋进新起点】基本医疗有保障'
      },
      '住房安全有保障': {
        title: '【脱贫感党恩 奋进新起点】住房安全有保障'
      },
      '饮水安全有保障': {
        title: '【脱贫感党恩 奋进新起点】饮水安全有保障'
      },
      '产业发展促增收': {
        title: '【脱贫感党恩 奋进新起点】产业发展促增收'
      },
      '党建引领促脱贫': {
        title: '【脱贫感党恩 奋进新起点】党建引领促脱贫'
      },
      '就业创业稳民生': {
        title: '【脱贫感党恩 奋进新起点】就业创业稳民生'
      },
      '基础设施促发展': {
        title: '【脱贫感党恩 奋进新起点】基础设施促发展'
      },
      '资金保障保护航': {
        title: '【脱贫感党恩 奋进新起点】资金保障保护航'
      },
      '脱贫-平乐镇': {
        title: '【脱贫感党恩 奋进新起点】平乐镇'
      },
      '脱贫-沙子镇': {
        title: '【脱贫感党恩 奋进新起点】沙子镇'
      },
      '脱贫-二塘镇': {
        title: '【脱贫感党恩 奋进新起点】二塘镇'
      },
      '脱贫-张家镇': {
        title: '【脱贫感党恩 奋进新起点】张家镇'
      },
      '脱贫-同安镇': {
        title: '【脱贫感党恩 奋进新起点】同安镇'
      },
      '脱贫-源头镇': {
        title: '【脱贫感党恩 奋进新起点】源头镇'
      },
      '脱贫-阳安乡': {
        title: '【脱贫感党恩 奋进新起点】阳安乡'
      },
      '脱贫-青龙乡': {
        title: '【脱贫感党恩 奋进新起点】青龙乡'
      },
      '脱贫-桥亭乡': {
        title: '【脱贫感党恩 奋进新起点】桥亭乡'
      },
      '脱贫-大发瑶族乡': {
        title: '【脱贫感党恩 奋进新起点】大发瑶族乡'
      },
      '党史学习教育': {
        title: '在习近平新时代中国特色社会主义思想指引下——迎建党百年 谱八桂新篇'
      },
      '经济工作': {
        title: '开局“十四五” 奋进新征程'
      },
      '乡村振兴': {
        title: '巩固拓展脱贫攻坚成果 全面推进乡村振兴'
      },
      '启航新征程': {
        title: '在习近平新时代中国特色社会主义思想指引下——奋斗百年路 启航新征程 谱写建设壮美广西共圆复兴梦想新篇'
      },
      '畅游桂林温暖过年': {
        title: '【畅游桂林 温暖过年】—— 县区文旅推介系列'
      },
      '党史知识大家答': {
        title: '感党恩 跟党走·党史知识大家答'
      },
      '平语近人': {
        title: '平“语”近人'
      },
      '网络中国节清明': {
        title: '网络中国节•清明'
      },
      '网络中国节端午': {
        title: '网络中国节•端午'
      },
      '锻造政法铁军': {
        title: '贯彻习近平法治思想，锻造新时代政法铁军'
      },
      '庆建党百年谱八桂新篇': {
        title: '庆建党百年 谱八桂新篇'
      },
      '奋斗百年路启航新征程': {
        title: '奋斗百年路 启航新征程——牢记初心使命 争取更大光荣'
      },
      '喜迎区第十二次党代会': {
        title: '以优异成绩迎接自治区第十二次党代会召开'
      },
      '学习十九届六中全会精神': {
        title: '深入学习宣传贯彻党的十九届六中全会精神'
      },
      '民族团结进步': {
        title: '铸牢中华民族共同体意识'
      }
    }
  },
  mutations: {},
  actions: {}
}
