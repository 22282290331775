<template>
  <div style="position: relative">
    <transition name="fade">
      <div class="wrap" v-if="show">
        <div class="item" v-for="item in items" :key="item.title" @click="go(item.to)">
          {{item.title}}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SelectMenu',
  props: {
    show: Boolean,
    items: Array
  },
  methods: {
    go (to) {
      this.$emit('update:show', !this.show)
      this.$router.push(to)
    }
  }
}
</script>

<style scoped>
  .item {
    background: white;
    color: #333;
    padding: 4vw;
    border-bottom: 0.1vw solid #eee;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .wrap {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 4vh;
    white-space: nowrap;
    box-shadow: 0 0 3vw #ddd;
  }
</style>
