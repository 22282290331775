import Vue from 'vue'
import Router from 'vue-router'
import { updateShareData } from './wx-jssdk'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'home',
    meta: {
      tabBarHidden: false
    },
    component: () => import(/* webpackChunkName: "Home" */ './views/Home.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      tabBarHidden: false
    },
    component: () => import(/* webpackChunkName: "profile" */ './views/Profile/Profile.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      tabBarHidden: false
    },
    component: () => import(/* webpackChunkName: "Login" */ './views/Profile/Login.vue')
  },
  {
    path: '/article/:articleId',
    name: 'article',
    meta: {
      tabBarHidden: true
    },
    component: () => import(/* webpackChunkName: "Article" */ './views/article/Article.vue')
  },
  {
    path: '/articles-page/:title',
    name: 'articles-page',
    meta: {
      tabBarHidden: false
    },
    component: () => import(/* webpackChunkName: "ArticlesPage" */ './views/ArticlesPage.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    meta: {
      tabBarHidden: true
    },
    component: () => import(/* webpackChunkName: "Preview" */ './views/Preview.vue')
  },
  {
    path: '/iframe-category-page/:title',
    name: 'iframe-category-page',
    meta: {
      tabBarHidden: false
    },
    component: () => import(/* webpackChunkName: "IframeCategoryPage" */ './views/IframeCategoryPage.vue')
  },
  {
    path: '/config/category',
    name: 'config-category',
    meta: {
      tabBarHidden: true
    },
    component: () => import(/* webpackChunkName: "IframeCategoryPage" */ './views/pc/ConfigCategory.vue')
  },
  {
    path: '/live',
    name: 'live',
    meta: {
      tabBarHidden: true
    },
    component: () => import(/* webpackChunkName: "IframeCategoryPage" */ './views/live.vue')
  }
  ]
})

router.beforeEach((to, from, next) => {
  // 菜单栏处理
  let store = router.app.$options.store
  store.commit('tabBarHidden', !!to.meta.tabBarHidden)

  let find = false
  for (let i in store.state.tabbar.config) {
    if (store.state.tabbar.config[i].to === to.path) {
      store.commit('tabBarActive', parseInt(i))
      find = true
    }
  }

  if (!find) {
    store.commit('tabBarActive', -1)
  }

  next()
})

router.afterEach(() => {
  // 分享处理
  updateShareData({
    title: '平乐手机台',
    desc: '平乐手机台',
    link: `${location.protocol}//${location.host}/redirect.html?appRedirect=${encodeURIComponent(location.href)}`
  })
})

export default router
