// import {
//   request
// } from '@/utils/request'

import wx from 'weixin-js-sdk'

// request({
//   url: 'wp-json/polls/v1/wxjs',
//   params: { url: location.href.split('#')[0] }
// }).then(res => {
//   if (location.href.indexOf('vConsole=true') !== -1) {
//     res.data.data.debug = true
//   }
//   wx.config(res.data.data)
// })

export function updateShareData(data) {
  wx.ready(() => {
    wx.updateAppMessageShareData(data)
    wx.updateTimelineShareData(data)
    wx.miniProgram.postMessage({
      data: { data }
    });
  })
}
