import Vue from 'vue'
import Vuex from 'vuex'
import tabbar from './tab-bar'
import articlePage from './article-page'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    tabbar,
    articlePage
  }
})
