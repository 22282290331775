export default {
  state: {
    active: 0,
    hidden: false,
    config: [
      {
        title: '首页',
        to: '/'
      },
      {
        title: '电视',
        to: '/articles-page/电视',
        icon: 'play'
      }
      // {
      //   title: '政策',
      //   to: '/articles-page/政策',
      //   icon: 'idcard'
      // },
      // {
      //   title: '广播',
      //   to: '/articles-page/广播',
      //   icon: 'share'
      // },
      // {
      //   title: '听书',
      //   to: '/articles-page/听书',
      //   icon: 'orders-o'
      // }
      // {
      //   title: '晚会',
      //   icon: 'fire-o',
      //   menu: [
      //     { title: '平乐春晚', to: '/articles-page/平乐春晚' },
      //     { title: '扫黑除恶专题晚会', to: '/articles-page/扫黑除恶专题晚会' }
      //   ]
      // },
      // {
      //   title: '脱贫(一)',
      //   icon: 'fire-o',
      //   menu: [
      //     { title: '习语', to: '/articles-page/习语' },
      //     { title: '义务教育有保障', to: '/articles-page/义务教育有保障' },
      //     { title: '基本医疗有保障', to: '/articles-page/基本医疗有保障' },
      //     { title: '住房安全有保障', to: '/articles-page/住房安全有保障' },
      //     { title: '饮水安全有保障', to: '/articles-page/饮水安全有保障' },
      //     { title: '产业发展促增收', to: '/articles-page/产业发展促增收' },
      //     { title: '党建引领促脱贫', to: '/articles-page/党建引领促脱贫' },
      //     { title: '就业创业稳民生', to: '/articles-page/就业创业稳民生' },
      //     { title: '基础设施促发展', to: '/articles-page/基础设施促发展' },
      //     { title: '资金保障保护航', to: '/articles-page/资金保障保护航' }
      //   ]
      // },
      // {
      //   title: '抖音',
      //   to: '/articles-page/抖音比赛',
      //   icon: 'music-o'
      // }
      // {
      //   title: '脱贫(二)',
      //   icon: 'fire-o',
      //   menu: [
      //     { title: '习语', to: '/articles-page/习语' },
      //     { title: '平乐镇', to: '/articles-page/脱贫-平乐镇' },
      //     { title: '沙子镇', to: '/articles-page/脱贫-沙子镇' },
      //     { title: '二塘镇', to: '/articles-page/脱贫-二塘镇' },
      //     { title: '张家镇', to: '/articles-page/脱贫-张家镇' },
      //     { title: '同安镇', to: '/articles-page/脱贫-同安镇' },
      //     { title: '源头镇', to: '/articles-page/脱贫-源头镇' },
      //     { title: '阳安乡', to: '/articles-page/脱贫-阳安乡' },
      //     { title: '青龙乡', to: '/articles-page/脱贫-青龙乡' },
      //     { title: '桥亭乡', to: '/articles-page/脱贫-桥亭乡' },
      //     { title: '大发瑶族乡', to: '/articles-page/脱贫-大发瑶族乡' }
      //   ]
      // }
    ]
  },
  mutations: {
    tabBarActive(state, newActive) {
      state.active = newActive
    },
    tabBarHidden(state, flag) {
      state.hidden = flag
    }
  },
  actions: {}
}
