<template>
  <div id="app">
    <router-view/>
    <tab-bar></tab-bar>
  </div>
</template>
<script>
import TabBar from '@/components/tab-bar'
import Vue from 'vue'
import { Menu, MenuItem, MenuItemGroup, Popover, Submenu } from 'element-ui'
Vue.component(Popover.name, Popover)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
export default {
  name: 'app',
  components: { TabBar },
  data () {
    return {}
  }
}
</script>
<style lang="stylus">
  *
    font-family: Helvetica, Arial, "微软雅黑", "黑体"

  body
    background: #efeff4
    font-size 4vw

  .pull-left
    float: left

  .pull-right
    float: right
</style>
