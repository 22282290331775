<template>
  <div class="pingle-tab-bar__">
    <van-tabbar v-model="activeValue" v-if="!hidden">
      <van-tabbar-item v-for="(config, index) in this.$store.state.tabbar.config" replace :to="config.to"
                       :icon="config.icon" :key="config.title" @click="showMenuIndex = showMenuIndex===index?-1:index">
        <select-menu v-if="config.menu" style="z-index: 10" :show="showMenuIndex === index"
                     @update:show="showMenuIndex=$event?-1:index"
                     :items="config.menu"></select-menu>
        {{config.title}}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import SelectMenu from './SelectMenu'

export default {
  name: 'tab-bar',
  components: {
    SelectMenu
  },
  props: {
    active: Number
  },
  data () {
    return {
      showGalaMenu: false,
      showMenuIndex: -1
    }
  },
  computed: {
    activeValue: {
      get () {
        return this.$store.state.tabbar.active
      },
      set (value) {
        this.$store.commit('tabBarActive', value)
      }
    },
    hidden () {
      return this.$store.state.tabbar.hidden
    }
  }
}
</script>

<style lang="stylus">
  .pingle-tab-bar__
    position: fixed
    bottom: 0
    z-index: 100
</style>
